<!-- Hero Section -->
<section class="big-feature-section">
  <div class="container flex big-feature-container" id="second-big-feature">
    <div class="feature-img">
      <img src="assets/herosection.jpeg" alt="HeyiDB - Innovating Digital Solutions" />
    </div>
    <div class="feature-desc flex">
      <h1>
        Empowering Innovation with <span class="highlight">HeyiDB</span>
      </h1>
      <p>
        <span class="brand-name">HeyiDB</span> is a technology-driven company committed to building
        <span class="bold-text">innovative, user-centric digital solutions</span> that enhance
        productivity, security, and professional networking.
      </p>
      <p>
        We specialize in developing applications that simplify
        <span class="bold-text">everyday challenges</span>—from secure document management to seamless
        industry connections. Our mission is to create
        <span class="bold-text">intuitive, privacy-first platforms</span> that empower individuals and
        businesses with <span class="bold-text">efficiency and control</span>.
      </p>
      <p>
        With products like <span class="bold-text">FilesCase</span> and <span class="bold-text">CineMatch</span>,
        we are shaping the future of <span class="bold-text">digital organization and creative networking</span>.
        Explore our platforms and experience <span class="bold-text">next-generation digital solutions</span> with
        HeyiDB.
      </p>
    </div>
  </div>
</section>

<!-- Products Section -->
<section class="products-section" id="products">
    <div class="container">
        <div class="products-header flex">
            <h2>Our Products</h2>
        </div>

        <div class="products-area">
            <div *ngFor="let product of products" class="product-card flex">
                <a class="product-image" [href]="product.link" [style.backgroundImage]="'url(' + product.image + ')'">
                    <div class="overlay">
                        <h3>{{ product.name }}</h3>
                    </div>
                </a>
                <div class="product-description">
                    <h3><a [href]="product.link" class="product-title">{{ product.name }}</a></h3>
                    <p [innerHTML]="product.description"></p>
                </div>
            </div>
        </div>
    </div>
</section>

