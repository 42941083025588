<section class="big-feature-section">
    <div class="container flex big-feature-container" id="second-big-feature">
        <div class="feature-img">
            <img src="./assets/herosection.jpeg" alt="">
        </div>
        <div class="feature-desc flex">
            <h1>Discover Custom GPT Solutions with HeyiDB</h1>
            <p>Embark on a journey of innovation with HeyiDB, your partner in AI-driven excellence. As a pioneering startup specializing in product-based AI advancements, we are at the forefront of crafting custom GPT models designed to revolutionize business processes. Our mission is to provide enterprises with cutting-edge AI capabilities, enhancing operational efficiency and empowering informed decision-making through data. Explore our bespoke GPT offerings and step into the future of business intelligence with HeyiDB.</p>
        </div>
    </div>
</section>

  <!-- products section -->

<section class="products-section" id="products">
    <div class="container">
        <div class="products-header flex">
            <h2>Products</h2>
            <!-- <a href="#" class="secondary-button">See more products <i class="fa-solid fa-right-long"></i></a> -->
        </div>
        <div class="products-area flex">
            <a class="products-card" href="/kiddogoals" style="background: linear-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.8)), url(./assets/kiddogoals.png); background-size: cover;">
                <h3 class="card-text">KiddoGoals (In Development)</h3>
            </a>
            <a target="_blank" class="products-card" href="https://chat.openai.com/g/g-lYamo812h-banter-scene-cartoonist" style="background: linear-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.8)), url(./assets/banterscenecartoonist.png); background-size: cover;">
                <h3 class="card-text">Banter Scene Cartoonist</h3>
            </a>
            <a target="_blank" class="products-card" href="https://chat.openai.com/g/g-kUA8lSMIh-business-analyst" style="background: linear-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.8)), url(./assets/businessanalyst.png); background-size: cover;">
                <h3 class="card-text">Business Analyst</h3>
            </a>
            <a target="_blank" class="products-card" href="https://chat.openai.com/g/g-z3CxqDp4X-kids-drawing-to-cartoon" style="background: linear-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.8)), url(./assets/kidsdrawing2cartoon.png); background-size: cover;">
                <h3 class="card-text">Kids Drawing to Cartoon</h3>
            </a>
        </div>
        <div class="products-area flex">
            <a target="_blank" class="products-card" href="https://chat.openai.com/g/g-NWBqKslVl-prompt-odyssey" style="background: linear-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.8)), url(./assets/promptodyssey.png); background-size: cover;">
                <h3 class="card-text">Prompt Odyssey</h3>
            </a>
            <a target="_blank" class="products-card" href="https://chat.openai.com/g/g-HKCR3TpMB-org-chart-assistant" style="background: linear-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.8)), url(./assets/orgchartassistant.png); background-size: cover;">
                <h3 class="card-text">Org Chart Assistant</h3>
            </a>
            <a target="_blank" class="products-card" href="https://chat.openai.com/g/g-db0FYjAcC-logo-craft-pro" style="background: linear-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.8)), url(./assets/logocraftpro.png); background-size: cover;">
                <h3 class="card-text">Logo Craft Pro</h3>
            </a>
            <a target="_blank" class="products-card" href="https://chat.openai.com/g/g-kKYCfdnS5-presentation-pro" style="background: linear-gradient(rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.8)), url(./assets/presentationpro.png); background-size: cover;">
                <h3 class="card-text">Presentation Pro</h3>
            </a>
        </div>
    </div>
</section>