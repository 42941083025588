import { Component } from '@angular/core';

@Component({
  selector: 'app-kiddo-goals-details',
  templateUrl: './kiddo-goals-details.component.html',
  styleUrls: ['./kiddo-goals-details.component.css']
})
export class KiddoGoalsDetailsComponent {
  title = 'KiddoGoals - Empowering Parents to Foster Positive Habits and Learning in Children';
  
  introduction = 'In today\'s fast-paced world, parents are constantly looking for effective ways to manage their children\'s habits and encourage positive behavior. KiddoGoals is an innovative app designed to meet this need by providing a comprehensive platform for tracking habits, setting goals, and fostering motivation through rewards and competition.';
  
  problemStatement = 'Parents often struggle to keep track of their children\'s habits and progress in overcoming bad behaviors. Existing tools are either too general or lack engaging features that motivate children. Moreover, the lack of collaborative and interactive environments makes it challenging for children to stay motivated and focused on their homework.';
  
  solution = 'KiddoGoals addresses these challenges by offering a robust habit tracking system, goal setting and rewards, educational resources, and a unique Virtual Homework Room feature. This comprehensive approach ensures that parents have all the tools they need to support their children\'s development while keeping them engaged and motivated.';
  
  keyFeatures = [
    'Habit Tracking and Progress Visualization: Track daily activities and visualize progress with charts and graphs.',
    'Goal Setting and Rewards: Set goals, track completion, and assign rewards to motivate children.',
    'Leaderboard: Introduce a competitive element with a points-based leaderboard to encourage healthy competition.',
    'Educational Resources: Access curated content to help tackle bad habits and support learning.',
    'Virtual Homework Room (VR): Create interactive virtual environments for collaborative homework sessions, enhancing motivation and social interaction.'
  ];
}