import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  products = [
    {
      name: 'FilesCase',
      link: 'https://play.google.com/store/apps/details?id=com.heyidb.filescasepro',
      image: './assets/filescase.png',
      description: `
        <span class="highlight">FilesCase</span> is a <span class="bold">secure, offline document manager</span> that allows users to
        <span class="bold">store, organize, and access</span> essential documents <span class="bold">directly on their device</span>—without relying on cloud storage.
        <br><br>
        Designed with <span class="bold">privacy in mind</span>, FilesCase ensures that your sensitive information remains under your <span class="bold">complete control</span>.
        Whether it’s IDs, invoices, or personal notes, <span class="bold">FilesCase simplifies document management</span> while maintaining <span class="bold">absolute security</span>.
      `
    },
    {
      name: 'CineMatch',
      link: 'https://play.google.com/store/apps/details?id=com.heyidb.cinematch',
      image: './assets/cinematch.png',
      description: `
        <span class="highlight">CineMatch</span> is a <span class="bold">networking platform for the film industry</span>, designed to connect
        <span class="bold">actors, directors, writers, and producers</span>.
        <br><br>
        By streamlining <span class="bold">talent discovery and collaboration</span>, CineMatch helps entertainment professionals
        <span class="bold">find projects, showcase their skills, and grow their careers</span>. Whether you're an aspiring filmmaker or an established industry expert,
        <span class="bold">CineMatch provides the right connections to bring your vision to life</span>.
      `
    }
  ];
  constructor() { }

  ngOnInit(): void {
    
  }

}
