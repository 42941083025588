<div class="kiddo-goals-details">
    <h1>{{title}}</h1>
    
    <section>
      <h2>Introduction</h2>
      <p>{{introduction}}</p>
    </section>
    
    <section>
      <h2>Problem Statement</h2>
      <p>{{problemStatement}}</p>
    </section>
    
    <section>
      <h2>Solution</h2>
      <p>{{solution}}</p>
    </section>
    
    <section>
      <h2>Key Features and Benefits</h2>
      <ul>
        <li *ngFor="let feature of keyFeatures">{{feature}}</li>
      </ul>
    </section>
    
    <a href="/" class="back-button">Back to Home</a>
  </div>